import React, { useState, useEffect } from "react";
import { navigate, Link } from "gatsby";

import { PROTOCOL } from "../protocol";
import ActivityCard from "../components/activityCard";
import { set, get } from "idb-keyval";
import { FiArrowLeftCircle } from "react-icons/fi";

const RelaxationProtocol = ({ location }) => {
  const [selectedDay, setSelectedDay] = useState(1);
  const [startedAct, setStartedAct] = useState(false);

  useEffect(() => {
    if (location.state.dayFromTable) {
      setSelectedDay(location.state.dayFromTable);
    } else {
      console.log("No State!");
    }
  }, []);

  const handleDaySelected = (e) => {
    setSelectedDay(e.target.value);
  };

  const updateDay = (success) => {
    const { locationState } = location.state;
    get("progress").then((val) => {
      if (val) {
        const newArray = val[locationState];
        if (success) {
          newArray[selectedDay - 1] = true;
        } else {
          newArray[selectedDay - 1] = false;
        }
        set("progress", { ...val, [locationState]: newArray })
          .then(() => {
            console.log("It worked!");
            navigate("/relaxation/");
          })
          .catch((err) => console.log("It failed!", err));
      } else {
        console.log("There is not data saved");
      }
    });
  };

  const renderDropdown = () => (
    <div className="field is-horizontal">
      <div className="field-label is-normal has-text-grey-lighter">
        <label className="label has-text-grey">Step</label>
      </div>
      <div className="field-body">
        <div className="field is-narrow">
          <div className="control">
            <div className="select is-rounded">
              <select value={selectedDay} onChange={handleDaySelected}>
                {PROTOCOL.map((proto) => (
                  <option key={proto.day} value={proto.day}>
                    {proto.day}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderIntro = () => {
    return (
      <>
        <h2 className="title" style={{ margin: "2rem 1rem 3rem 1rem" }}>
          Today you will:
        </h2>
        {PROTOCOL.find((day) => day.day == selectedDay).intro.map(
          (sentence, index) => (
            <p
              key={index}
              className="is-size-4"
              style={{ marginBottom: "2rem" }}
            >
              {sentence}
            </p>
          )
        )}
        <button
          className="button is-rounded is-success is-large"
          style={{ marginTop: "3rem" }}
          onClick={() => setStartedAct(true)}
        >
          Begin step {selectedDay}
        </button>
      </>
    );
  };

  return (
    <div
      className="has-text-centered"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        padding: "1rem",
        maxWidth: "900px",
        margin: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Link to="/relaxation/">
          <span className="icon is-large" style={{ margin: "0.5rem" }}>
            <FiArrowLeftCircle className="is-size-2 has-text-grey" />
          </span>
        </Link>
        {renderDropdown()}
        <p style={{ margin: "0.5rem" }}>
          {" "}
          calm<span className="has-text-success">dog</span>
        </p>
      </div>
      {!startedAct ? (
        renderIntro()
      ) : (
        <ActivityCard
          activities={
            PROTOCOL.find((meow) => meow.day == selectedDay).activities
          }
          updateDay={updateDay}
        />
      )}
    </div>
  );
};

export default RelaxationProtocol;
