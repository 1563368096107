import React, { useState, useEffect } from "react";
import { FiRotateCcw } from "react-icons/fi";

const ActivityCard = ({ activities, updateDay }) => {
  const [currentAct, setCurrentAct] = useState(0);
  const [seconds, setSeconds] = useState(5);
  const [isActive, setIsActive] = useState(false);
  const [results, setResults] = useState({});
  const [hasFailed, setHasFailed] = useState(false);
  const [done, setDone] = useState(false);
  const [message, setMessage] = useState("Ready...");

  useEffect(() => {
    setCurrentAct(0);
  }, [activities]);

  useEffect(() => {
    reset();
    if (activities[currentAct].timer) {
      setIsActive(false);
      setSeconds(activities[currentAct].timer);
      setMessage("Ready...");
      setTimeout(() => {
        setIsActive(true);
        setMessage("GO!");
      }, 1000);
      setTimeout(() => {
        setMessage("Set...");
      }, 500);
    }
  }, [currentAct]);

  const renderTimer = (time) => {
    return (
      <div
        style={{
          minHeight: "50px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1rem",
          backgroundColor: `${done ? "hsl(141, 71%, 48%)" : "white"}`,
          borderRadius: "25px",
        }}
      >
        <span className="icon is-pulled-left is-large" onClick={reset}>
          <FiRotateCcw className="is-size-3" />
        </span>
        <h2>{message}</h2>
        <div
          onClick={toggle}
          style={{
            float: "right",
            height: "50px",
            width: "50px",
            border: `2px solid ${
              !isActive ? "hsl(48, 100%, 67%)" : "hsl(141, 71%, 48%)"
            }`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50px",
            fontSize: "1.4rem",
          }}
        >
          {seconds}
        </div>
      </div>
    );
  };

  function toggle() {
    setIsActive(!isActive);
  }

  function reset() {
    if (activities[currentAct].timer) {
      setSeconds(activities[currentAct].timer);
      setDone(false);
      setMessage("Go!");
    }
  }

  const passActivity = (passedAct) => {
    if (!results[passedAct]) {
      setResults({ ...results, [passedAct]: "pass" });
    } else if (results[passedAct] === "first fail") {
      setResults({ ...results, [passedAct]: "pass with one fail" });
    }
    setCurrentAct(passedAct);
  };

  const handleFail = (failedAct) => {
    if (!results[failedAct]) {
      setResults({ ...results, [failedAct]: "first fail" });
      reset();
    } else if (results[failedAct] === "first fail") {
      setResults({ ...results, [failedAct]: "failed twice" });
      setCurrentAct(failedAct);
    }
    setHasFailed(true);
  };

  useEffect(() => {
    let interval = null;
    if (seconds === 0) {
      clearInterval(interval);
      setDone(true);
      setMessage("Yes!");
    } else if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const renderPass = () => {
    if (currentAct < activities.length - 1) {
      return (
        <button
          className="button is-success is-rounded is-large is-fullwidth"
          style={{ margin: "0.5rem" }}
          onClick={() => passActivity(currentAct + 1)}
        >
          YES!
        </button>
      );
    } else {
      return (
        <button
          className="button is-success is-rounded is-large is-fullwidth"
          style={{ margin: "0.5rem" }}
          onClick={() => updateDay(!hasFailed)}
        >
          Finish
        </button>
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <div style={{ maxHeight: "300px" }}>
        {activities[currentAct].text.length > 40 ? (
          <h3
            className="is-size-4 has-text-centered "
            style={{ padding: "3rem 1.5rem 0 1.5rem" }}
          >
            {activities[currentAct].text}
          </h3>
        ) : (
          <h3
            className="is-size-2 has-text-centered "
            style={{ padding: "3rem 1.5rem 0 1.5rem" }}
          >
            {activities[currentAct].text}
          </h3>
        )}
        {results[currentAct + 1] === "first fail" && (
          <div className="notification is-danger is-light">
            <h3>
              Try one more time, but break the task down into smaller tasks that
              the dog can do
            </h3>
          </div>
        )}
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        {activities[currentAct].timer &&
          renderTimer(activities[currentAct].timer)}
        <h2>
          Step {currentAct + 1} out of {activities.length}{" "}
        </h2>
        <progress
          className="progress is-success"
          value={currentAct + 1}
          max={activities.length}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {currentAct < activities.length - 1 && (
            <button
              className="button is-rounded is-large is-danger is-outlined is-fullwidth"
              style={{
                margin: "0.5rem",
              }}
              onClick={() => {
                handleFail(currentAct + 1);
              }}
            >
              FAIL
            </button>
          )}
          {renderPass()}
        </div>
      </div>
    </div>
  );
};

export default ActivityCard;
